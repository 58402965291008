import {isNotNullOrUndefined, isNullOrUndefined} from '../utils.static';
import {GeneralTabForm} from '../../model/creation/general-tab.form';
import {UserRespForm} from '../../model/creation/user-resp.form';
import {ProgramEnum} from '../../model/enums/program.enum';
import {User} from '../../model/user/user.model';
import {SchoolLevel} from '../../model/user/school-level.model';
import {BehaviorSubject} from 'rxjs';
import {SdlvTabForm} from '../../model/creation/sdlv-tab.form';
import {ScolarshipStatusEnum} from '../../model/enums/scolarship-status.enum';
import {JobTabForm} from '../../model/creation/job-tab.form';
import {UntypedFormGroup} from '@angular/forms';

interface ErrorByTab {
  tab: string;
  errors: string[];
}

export interface FirstPageErrors {
  programList: BehaviorSubject<unknown[]>;
  generalTab: GeneralTabForm;
  placeFormGroup: UntypedFormGroup;
  infoUserGeneralFormGroup: UntypedFormGroup;
  infoYoungGeneralFormGroup: UntypedFormGroup;
  testUserInfoFormGroup: UntypedFormGroup;
  infoRespLegalFormGroup: UntypedFormGroup;
  checkAtLeastOne: boolean;
  isRespSameAddress: boolean;
}

export class YoungCreationErrorHelper {
  static getFirstPageErrors(
    firstPageErrors: FirstPageErrors
  ): string[] {
    const generalErrors: Set<string> = new Set<string>();
    let errors: string[] = [];

    if (!firstPageErrors.programList.value || firstPageErrors.programList.value.length === 0) {
      generalErrors.add('programs');
    }

    this.getErrorsFromFormGroup(
      firstPageErrors.placeFormGroup,
      firstPageErrors.infoUserGeneralFormGroup,
      firstPageErrors.infoYoungGeneralFormGroup,
      generalErrors
    );

    if (!firstPageErrors.checkAtLeastOne) {
      generalErrors.add('phone&email');
    }

    if (
      // S'il est mineur et qu'aucun responsable légal n'est renseigné
      (!this.isMajor(firstPageErrors.testUserInfoFormGroup) && (firstPageErrors.infoRespLegalFormGroup.controls?.families?.value?.length === 0 || firstPageErrors.infoRespLegalFormGroup.invalid))
      ||
      // S'il est majeur et que le responsable légal est renseigné et valide ou est désactivé
      (this.isMajor(firstPageErrors.testUserInfoFormGroup) && firstPageErrors.infoRespLegalFormGroup.status !== 'DISABLED' && firstPageErrors.infoRespLegalFormGroup.invalid)
    ) {
      errors = errors.concat(this._testResponsable(firstPageErrors.generalTab, this.isMajor(firstPageErrors.testUserInfoFormGroup), firstPageErrors.isRespSameAddress));
    }

    errors = errors.concat(Array.from(generalErrors).map((error) => this.formatErrors(error)));

    return errors;
  }

  static formatErrors(formControlName: string) {
    switch (formControlName) {
      case 'sex':
        return "Le sexe n'est pas renseigné";
      case 'email':
        return "L'email n'est pas renseigné ou n'a pas le bon format";
      case 'phone':
        return "Le téléphone n'est pas renseigné";
      case 'address':
        return "L'adresse n'est pas renseignée";
      case 'postCode':
        return "Le code postal n'est pas renseigné";
      case 'city':
        return "La ville n'est pas renseignée";
      case 'nameCenter':
        return "Le centre n'est pas renseigné";
      case 'responsable':
        return "Le responsable légal n'est pas renseigné";
      case 'programs':
        return 'Un programme doit être choisi';
      case 'phone&email':
        return "Le téléphone et l'email doivent être renseignés";
      default:
        return '';
    }
  }

  static isMajor(testUserinfoFormGroup: UntypedFormGroup) {
    return User.formatAge(testUserinfoFormGroup.controls.birthday.value) >= 18;
  }

  static getInvalidControls(formGroup: UntypedFormGroup) {
    const invalid = [];
    const controls = formGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  static testAll(
    generalTab: GeneralTabForm,
    sdlvTabs: SdlvTabForm,
    jobTabs: JobTabForm,
    schoolLevels: SchoolLevel[],
    programList: BehaviorSubject<unknown[]>,
    isRespSameAddress: boolean
  ): ErrorByTab[] {
    const result: {tab: string; errors: string[]}[] = [];
    const isInSDLV = programList?.getValue()?.find((item) => item === ProgramEnum.SPORTDANSLAVILLE);
    const isInJob = programList?.getValue()?.find((item) => item === ProgramEnum.JOBDANSLAVILLE);

    let errors: string[];

    errors = this._testGeneral(generalTab, schoolLevels, isRespSameAddress);

    if (!isInSDLV && !isInJob) {
      if (errors.length > 0) {
        errors.push('Le jeune doit être inscrit à au moins un programme');
      } else {
        errors = ['Le jeune doit être inscrit à au moins un programme'];
      }
    }

    if (errors.length > 0) {
      result.push({
        tab: 'Informations générales',
        errors
      });
    }
    if (isInSDLV) {
      errors = this._testSDLV(sdlvTabs);
      if (errors.length > 0) {
        result.push({
          tab: 'Sport dans la Ville',
          errors
        });
      }
    }
    if (isInJob) {
      errors = this._testJob(jobTabs);
      if (errors.length > 0) {
        result.push({
          tab: 'Job dans la Ville',
          errors
        });
      }
    }
    return result;
  }

  private static getErrorsFromFormGroup(
    placeFormGroup: UntypedFormGroup,
    infoUserGeneralFormGroup: UntypedFormGroup,
    infoYoungGeneralFormGroup: UntypedFormGroup,
    errors: Set<string>
  ) {
    if (placeFormGroup.invalid) {
      const invalids = this.getInvalidControls(placeFormGroup);
      invalids.forEach((invalid) => errors.add(invalid));
    }
    if (infoUserGeneralFormGroup.invalid) {
      const invalids = this.getInvalidControls(infoUserGeneralFormGroup);
      invalids.forEach((invalid) => errors.add(invalid));
    }
    if (infoYoungGeneralFormGroup.invalid) {
      const invalids = this.getInvalidControls(infoYoungGeneralFormGroup);
      invalids.forEach((invalid) => errors.add(invalid));
    }
  }

  private static _testGeneral(
    generalTab: GeneralTabForm,
    schoolLevels: SchoolLevel[],
    isRespSameAddress: boolean,
    isYoung = true
  ): string[] {
    let result = [];
    let isUnder18 = false;

    isUnder18 = User.formatAge(generalTab.birthday) < 18;
    if (
      !generalTab.lastName ||
      generalTab.lastName.length === 0 ||
      !generalTab.firstName ||
      generalTab.firstName.length === 0
    ) {
      result.push('Le jeune doit avoir un nom et un prénom');
    }

    if (!generalTab.birthday) {
      result.push('Le jeune doit avoir une date de naissance');
    }

    this.youngHasCenter(generalTab, result);

    this.youngHasSexAndPhoneAndAddress(generalTab, result, isUnder18);

    result = this.checkRespIfMinor(generalTab, isUnder18, isRespSameAddress, result);

    if (
      schoolLevels?.length > 0 &&
      schoolLevels.some((x) => x.idPSchoolLevel === null || x.dateStart === null)
    ) {
      result.push('Un niveau scolaire doit posséder une classe et une année scolaire');
    }

    return result;
  }

  private static _testSDLV(sdlvTabs: SdlvTabForm, isYoung = true): string[] {
    const result = [];

    if (!sdlvTabs.activity || sdlvTabs.activity.length === 0) {
      result.push('Le jeune doit être inscrit à au moins une activité');
    }

    sdlvTabs.comeAlone = !!sdlvTabs.comeAlone;

    return result;
  }

  private static _testJob(jobTabs: JobTabForm): string[] {
    const result = [];

    this.showErrorJobInfoYoung(jobTabs, result);

    this.showErrorJobInfoJob(jobTabs, result);

    this.showErrorsJobNeed(jobTabs, result);

    return result;
  }

  private static showErrorsJobNeed(jobTabs: JobTabForm, result: string[]) {
    this.showErrorWorkExperienceNeed(jobTabs, result);

    this.showErrorJobSubInfoNeed(jobTabs, result);

    if (jobTabs.sponsorWish && isNullOrUndefined(jobTabs.sponsorSex)) {
      result.push('Besoin jeune: Veuillez selectionner un sexe pour le parrain');
    }
  }

  private static showErrorJobSubInfoNeed(jobTabs: JobTabForm, result: string[]) {
    if (!jobTabs.jobbeurAccompaniment || jobTabs.jobbeurAccompaniment.length === 0) {
      result.push('Besoin jeune : Au moins un besoin en accompagnement doit être saisi.');
    }

    if (!jobTabs.interestFields || jobTabs.interestFields.length === 0) {
      result.push("Besoin jeune : Au moins un secteur d'interêt doit être saisi.");
    }

    if (!jobTabs.domainMetiers || jobTabs.domainMetiers.length === 0) {
      result.push('Besoin jeune : Au moins un domaine métier doit être saisi.');
    }
  }

  private static showErrorWorkExperienceNeed(jobTabs: JobTabForm, result: string[]) {
    if (jobTabs.hasJobbeurNeed) {
      if (!jobTabs.jobbeurNeed || jobTabs.jobbeurNeed.length === 0) {
        result.push(
          'Besoin jeune : Au moins un besoin en expérience professionnelle doit être saisi.'
        );
      } else {
        if (
          jobTabs.jobbeurNeed.some(
            (j) =>
              isNullOrUndefined(j.idPNeed) || !j.expectedDeadline || isNullOrUndefined(j.duration)
          )
        ) {
          result.push(
            'Besoin jeune : Au moins un besoin en expérience professionelle est invalide'
          );
        }
      }
    }
  }

  private static showErrorJobInfoJob(jobTabs: JobTabForm, result: string[]) {
    if (isNullOrUndefined(jobTabs.subProgramDate)) {
      result.push("Informations job : La date d'entrée dans le programme doit être renseignée.");
    }

    if (isNullOrUndefined(jobTabs.subProgram)) {
      result.push('Informations job : Le sous-programme doit être choisi.');
    }

    this.showErrorScolarshipStatus(jobTabs, result);

    this.showErrorEndSchoolDate(jobTabs, result);

    this.showErrorHighestDiploma(jobTabs, result);
  }

  private static showErrorScolarshipStatus(jobTabs: JobTabForm, result: string[]) {
    if (isNullOrUndefined(jobTabs.scolarshipStatus)) {
      result.push('Informations job : Le statut scolaire doit être renseigné.');
    }
  }

  private static showErrorHighestDiploma(jobTabs: JobTabForm, result: string[]) {
    if (isNullOrUndefined(jobTabs.highestLevel)) {
      result.push('Informations job : Le plus haut diplôme obtenu doit être renseigné.');
    } else {
      if (jobTabs.highestLevel > 1 && isNullOrUndefined(jobTabs.highestLevelDate)) {
        result.push(
          "Informations job : La date d'obtention du plus haut diplôme doit être renseigné."
        );
      }
    }
  }

  private static showErrorEndSchoolDate(jobTabs: JobTabForm, result: string[]) {
    if (
      (jobTabs.scolarshipStatus === ScolarshipStatusEnum.UNSCHOOLED ||
        jobTabs.scolarshipStatus === ScolarshipStatusEnum.COMPLETED_STUDIES) &&
      (jobTabs.endSchoolDate === undefined || jobTabs.endSchoolDate === null)
    ) {
      result.push("Informations job : La date de fin d'études doit être renseignée.");
    }
  }

  private static showErrorJobInfoYoung(jobTabs: JobTabForm, result: string[]) {
    if (isNullOrUndefined(jobTabs.idRi)) {
      result.push("Informations jeune : Le responsable d'insertion doit être saisi.");
    }

    if (isNullOrUndefined(jobTabs.idOrigin)) {
      result.push("Informations jeune : L'origine du jeune doit être remplie.");
    }
  }

  private static youngHasSexAndPhoneAndAddress(
    generalTab: GeneralTabForm,
    result: string[],
    isUnder18: boolean
  ) {
    if (isNullOrUndefined(generalTab.sex)) {
      result.push('Le jeune doit avoir un sexe');
    }

    if (!isUnder18 && !generalTab.phone) {
      result.push('Le jeune doit avoir une adresse mail ou un numéro de téléphone');
    }

    if (!generalTab.address || !generalTab.postCode || !generalTab.city) {
      result.push('Le jeune doit avoir une adresse complète');
    }
  }

  private static checkRespIfMinor(
    generalTab: GeneralTabForm,
    isUnder18: boolean,
    isRespSameAddress: boolean,
    result: string[]
  ) {
    if (
      isUnder18 ||
      isNotNullOrUndefined(generalTab.resp1) ||
      isNotNullOrUndefined(generalTab.resp2)
    ) {
      result = result.concat(this._testResponsable(generalTab, isUnder18, isRespSameAddress));
    }
    return result;
  }

  private static youngHasCenter(generalTab: GeneralTabForm, result: string[]) {
    if (!generalTab.place || !generalTab.nameCenter || generalTab.nameCenter.length === 0) {
      result.push('Le jeune doit être rattaché à un centre');
    }
  }

  private static _testResponsable(generalTab: GeneralTabForm, isUnder18: boolean, isRespSameAddress: boolean): string[] {
    const result = [];

    if (isUnder18 && !generalTab.resp1 && !generalTab.resp2) {
      result.push('Le jeune doit avoir au moins un responsable légal.');
      return result;
    }

    this.testResponsable1(generalTab, result);

    this.testResponsable2(generalTab, isRespSameAddress, result);

    return result;
  }

  private static testResponsable1(generalTab: GeneralTabForm, result: string[]) {
    if (isNotNullOrUndefined(generalTab.resp1)) {
      this.testResponsable(generalTab.resp1, result, true);
    }
  }

  private static testResponsable2(generalTab: GeneralTabForm, isRespSameAddress: boolean, result: string[]) {
    if (isNotNullOrUndefined(generalTab.resp2)) {
      this.testResponsable(generalTab.resp2, result, false, isRespSameAddress);
    }
  }

  private static testResponsable(resp: UserRespForm, result: string[], isPrincipal: boolean, isRespSameAddress = false) {
    this.respHasName(resp, result, isPrincipal);

    this.respHasPhone(resp, result, isPrincipal);

    this.respHasMail(resp, result, isPrincipal);

    this.respHasSex(resp, result, isPrincipal);

    if (!isRespSameAddress) {
      this.respHasAddress(resp, result, isPrincipal);
    }
  }

  private static respHasName(resp: UserRespForm, result: string[], isPrincipal: boolean) {
    if (
      !resp.lastName ||
      resp.lastName.length === 0 ||
      !resp.firstName ||
      resp.firstName.length === 0
    ) {
      result.push(
        `Le responsable ${isPrincipal ? 'principal' : 'secondaire'} doit avoir un nom et un prénom.`
      );
    }
  }

  private static respHasPhone(resp: UserRespForm, result: string[], isPrincipal: boolean) {
    if (!resp.phone) {
      result.push(
        `Le responsable ${
          isPrincipal ? 'principal' : 'secondaire'
        } doit avoir un numéro de téléphone.`
      );
    }
  }

  private static respHasMail(resp: UserRespForm, result: string[], isPrincipal: boolean) {
    if (!resp.email) {
      result.push(
        `Le responsable ${isPrincipal ? 'principal' : 'secondaire'} doit avoir une adresse email.`
      );
    }
  }

  private static respHasAddress(resp: UserRespForm, result: string[], isPrincipal: boolean) {
    if (!resp.address || !resp.postCode || !resp.city) {
      result.push(
        `Le responsable ${
          isPrincipal ? 'principal' : 'secondaire'
        } doit avoir une adresse complète.`
      );
    }
  }

  private static respHasSex(resp: UserRespForm, result: string[], isPrincipal: boolean) {
    if (isNullOrUndefined(resp.sex)) {
      result.push(`Le responsable ${isPrincipal ? 'principal' : 'secondaire'} doit avoir un sexe.`);
    }
  }
}
